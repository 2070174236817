<template>
  <div data-component-name="AppInputVerification">
    <div class="input-verification-wrapper">
      <div class="input-container">
        <AppInput
          v-maska:modelValue.unmasked="mask"
          :class="{ error: has($slots, 'error') }"
          :placeholder
          :maxlength
          :required
          :label
          size="medium"
          @keydown.enter="verifyHandler"
        />

        <SvgSpinner :class="['spinner', { visible: status === STATUS.PENDING }]" />
        <SvgCheck :class="['check', { visible: status === STATUS.FULFILLED }]" />
      </div>

      <Buttons.Regular
        :disabled="!canVerify"
        accent="purple"
        type="button"
        size="medium"
        @click="verifyHandler"
      >
        {{ $t('Verify') }}
      </Buttons.Regular>
    </div>

    <slot name="error" />
  </div>
</template>

<script setup lang="ts">
// https://beholdr.github.io/maska/v3/#/vue
import { vMaska } from 'maska/vue';

// components
import Buttons from '~/components/redesign/Buttons';
import AppInput from '~/components/redesign/AppInput.vue';

// constants
import { STATUS } from '~/types/request';

// utils
import has from 'lodash.has';

const modelValue = defineModel<string>('modelValue', { default: '' });
const status = defineModel<STATUS>('status', { default: STATUS.IDLE });

const props = withDefaults(
  defineProps<{
    mask?: string
    label?: string
    placeholder?: string
    maxlength?: string | number
    required?: boolean
    valid?: boolean
  }>(),
  {
    required: false,
    valid: false,
  },
);

const emit = defineEmits(['verify']);

defineSlots<{ error?: () => any }>();

const canVerify = computed(() => props.valid && status.value === STATUS.IDLE);

const verifyHandler = () => {
  if (canVerify.value) {
    emit('verify');
  }
};

watch(modelValue, () => {
  if ([STATUS.FULFILLED, STATUS.REJECTED].includes(status.value)) {
    status.value = STATUS.IDLE;
  }
});

defineExpose({ modelValue });
</script>

<style scoped lang="scss">
@import "$/mixins/flex";
@import "$/mixins/size";
@import "$/mixins/animation";

$icon-height: 1.5rem;

[data-component-name="AppInputVerification"] {
  .input-verification-wrapper {
    @include flex-stretch-sb;

    .input-container {
      width: 100%;
      position: relative;

      svg {
        $icon-size: 1.5rem;

        position: absolute;
        right: 1rem;
        top: calc(50% - (#{$icon-size} / 2));

        overflow: hidden;
        pointer-events: none;
        @include fixed-size($icon-size);

        opacity: 0;
        transition: opacity 0.15s ease;

        &.visible {
          opacity: 1;

          transition: 0.15s opacity 0.15s ease;
        }

        &.spinner {
          @include rotate;
          animation: rotate 1s infinite linear;
        }

        &.check {
          transform: scale(1.5);
        }
      }
    }

    [data-component-name="Buttons.Regular"] {
      min-height: 100%;
    }
  }
}
</style>
